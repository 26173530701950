import React, { useState, useEffect } from 'react';
import './App.css';
import { FaTelegram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaInfoCircle } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Analytics } from '@vercel/analytics/react';
import PriceTracker from './components/PriceTracker';
import pampiLogo from './assets/pampi-branding.png';
// import TransactionFlowVisual from './components/TransactionFlowVisual';
import PurchaseProgressVisual from './components/PurchaseProgressVisual';

function App() {
  const tokenData = {
    PLS: {
      symbol: 'PLS',
      image: '/images/pls.png'
    },
    HEX: {
      symbol: 'HEX',
      image: '/images/hex.png'
    },
    PLSX: {
      symbol: 'PLSX',
      image: '/images/plsx.png'
    },
    INC: {
      symbol: 'INC',
      image: '/images/inc.png'
    }
  };

  const tokens = Object.keys(tokenData);

  const [selectedTab, setSelectedTab] = useState('hex'); // Changed default to 'hex'
  const [reserveAmount, setReserveAmount] = useState('171088');  // Changed to '0'
  const [ethPrice, setEthPrice] = useState('3400');
  const [inputFields, setInputFields] = useState(2); // Default to 2 fields for HEX/PLSX
  const [inputValues, setInputValues] = useState([
    { amount: '', token: 'HEX' },
    { amount: '', token: 'PLSX' }
  ]); // Default setup for HEX/PLSX
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([
    { ticker: 'PLS', initialPrice: '0.000058', newPrice: '0.000058', increase: '2' },
    { ticker: 'PLSX', initialPrice: '0.000058', newPrice: '0.00007', increase: '2' },
    { ticker: 'INC', initialPrice: '0.00007', newPrice: '0.00007', increase: '2' },
    { ticker: 'HEX', initialPrice: '0.000058', newPrice: '0.00007', increase: '2' }
  ]);

  // Add this state for slider
  const [sliderValue, setSliderValue] = useState(0);

  // Add state to store the base ETH price
  const [baseEthPrice, setBaseEthPrice] = useState('3400');

  // Add this state near your other useState declarations
  const [isCalculating, setIsCalculating] = useState(false);

  // Add state for modal
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  // Add to the existing useState declarations in App.js
  const [simulationData, setSimulationData] = useState(null);
  const [showVisualizations, setShowVisualizations] = useState(false);

  // Add state for info tooltip
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  // Helper function to format numbers with commas
  const formatNumber = (num) => {
    return Math.round(num).toLocaleString('en-US');
  };

  useEffect(() => {
    // Watch for tab changes and update distributions
    const updateDistribution = () => {
      const reserveValue = parseInt(reserveAmount.replace(/,/g, '')) || 0;
      
      switch(selectedTab) {
        case 'hex':
          // Split between HEX and PLSX
          const splitTwo = Math.round(reserveValue / 2);
          setInputFields(2);
          setInputValues([
            { amount: formatNumber(splitTwo), token: 'HEX' },
            { amount: formatNumber(splitTwo), token: 'PLSX' }
          ]);
          break;
          
        case 'hexplsx':
          // Split between HEX, PLSX, and INC
          const splitThree = Math.round(reserveValue / 3);
          setInputFields(3);
          setInputValues([
            { amount: formatNumber(splitThree), token: 'HEX' },
            { amount: formatNumber(splitThree), token: 'PLSX' },
            { amount: formatNumber(splitThree), token: 'INC' }
          ]);
          break;
          
        case 'even':
          // Split between all four tokens
          const splitFour = Math.round(reserveValue / 4);
          setInputFields(4);
          setInputValues([
            { amount: formatNumber(splitFour), token: 'HEX' },
            { amount: formatNumber(splitFour), token: 'PLSX' },
            { amount: formatNumber(splitFour), token: 'INC' },
            { amount: formatNumber(splitFour), token: 'PLS' }
          ]);
          break;
          
        case 'custom':
          // Reset to single HEX field with 0
          setInputFields(1);
          setInputValues([
            { amount: '0', token: 'HEX' }
          ]);
          break;
      }
    };

    updateDistribution();
  }, [selectedTab, reserveAmount]); // Depend on tab changes and reserve amount changes

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || '';
        const response = await fetch(`${apiUrl}/api/eth-price`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        if (data.price) {
          const ethPriceValue = Math.round(parseFloat(data.price)).toString();
          setBaseEthPrice(ethPriceValue); // Store the base price
          setEthPrice(ethPriceValue);
          setSliderValue(0); // Ensure slider starts at leftmost position
          
          // Calculate and set reserve amount
          const reserveEth = 171088;
          const reserveUsd = Math.round(reserveEth * parseFloat(ethPriceValue));
          setReserveAmount(formatNumber(reserveUsd));
        }
      } catch (error) {
        console.error('Error fetching ETH price:', error);
      }
    };

    fetchEthPrice();
    
    // Fetch price every 5 minutes
    const interval = setInterval(fetchEthPrice, 5 * 60 * 1000);
    
    return () => clearInterval(interval);
  }, []);

  const getAvailableTokens = (currentIndex) => {
    const selectedTokens = inputValues.map(input => input.token);
    return tokens.filter((token, index) => !selectedTokens.includes(token) || selectedTokens.indexOf(token) === currentIndex);
  };

  const handleAddField = () => {
    if (inputFields < 4) {
      setShowResults(false); // Hide results when adding a field
      
      // Switch to custom split tab if on hex or hexplsx tab
      if (selectedTab === 'hex' || selectedTab === 'hexplsx') {
        setSelectedTab('custom');
      }
      
      const currentTokens = inputValues.map(input => input.token);
      const availableTokens = tokens.filter(token => !currentTokens.includes(token));
      const defaultToken = availableTokens[0] || tokens[0];

      setInputFields(inputFields + 1);
      setInputValues([...inputValues, { amount: '', token: defaultToken }]);
    }
  };

  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
    setShowResults(false);
  };

  const handleInputChange = (index, value) => {
    setShowResults(false); // Hide results on input change
    const newValues = [...inputValues];
    // Remove any non-numeric characters and format the number
    const numericValue = value.replace(/[^0-9]/g, '');
    newValues[index].amount = formatNumber(numericValue);
    setInputValues(newValues);
  };

  const handleTokenChange = (index, token) => {
    const isTokenUsed = inputValues.some((input, i) => i !== index && input.token === token);
    
    if (!isTokenUsed) {
      const newValues = [...inputValues];
      newValues[index].token = token;
      setInputValues(newValues);
    }
  };

  const handleCalculate = async () => {
    setIsCalculating(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || '';
      const sacMoney = inputValues.map(input => input.amount.replace(/,/g, '') || '0').join(',');
      const tickers = inputValues.map(input => input.token).join(',');
      
      const response = await fetch(
        `${apiUrl}/calculate?sacMoney=${sacMoney}&ticker=${tickers}&adjustmentPercentage=0`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      // Save the full simulation data
      setSimulationData(data);

      const formatPrice = (price) => {
        const numPrice = parseFloat(price);
        
        if (numPrice < 0.0001) {
          // For very small numbers (0.000042), show 6 decimal places
          return numPrice.toFixed(6);
        } else if (numPrice < 0.01) {
          // For just small numbers (0.0034), show 4 decimal places
          return numPrice.toFixed(4);
        } else if (numPrice < 0.1) {
          // For small numbers (0.034), show 3 decimal places
          return numPrice.toFixed(3);
        } else {
          // For larger numbers (1.29), show 2 decimal places
          return numPrice.toFixed(2);
        }
      };

      const newResults = tokens.map(token => {
        const initialPrice = parseFloat(data.originalPricesAndLPValues[`price${token}`]);
        const newPrice = parseFloat(data.final_results[`price${token}`]);
        
        // Calculate the exact multiplier with 2 decimal places (X times increase)
        const increase = (newPrice / initialPrice).toFixed(2);

        return {
          ticker: token,
          initialPrice: formatPrice(initialPrice),
          newPrice: formatPrice(newPrice),
          increase: increase + 'X'
        };
      });

      setResults(newResults);
      setShowResults(true);
      setShowVisualizations(false); // Start with visualizations hidden

      // Add this: Scroll to results after they're shown
      setTimeout(() => {
        const resultsSection = document.querySelector('.ResultsSection');
        if (resultsSection) {
          resultsSection.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100); // Small delay to ensure results are rendered

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsCalculating(false);
    }
  };

  const handleReserveChange = (value) => {
    setShowResults(false); // Hide results when reserve amount changes
    const numericValue = value.replace(/[^0-9]/g, '');
    setReserveAmount(formatNumber(numericValue));
  };

  const handleEthPriceChange = (value, isSlider = false) => {
    setShowResults(false);
    
    let newPrice;
    if (isSlider) {
      // Convert slider position to multiplier (1x, 2x, 4x, 8x, 16x)
      setSliderValue(value);
      const multiplier = Math.pow(2, value/50); // 0->1x, 50->2x, 100->4x, 150->8x, 200->16x
      newPrice = Math.round(parseFloat(baseEthPrice) * multiplier);
    } else {
      // Direct input handling
      newPrice = value.replace(/[^0-9$]/g, '').replace('$', '');
      setBaseEthPrice(newPrice); // Update base price when manually entered
      setSliderValue(0); // Reset slider to leftmost position when direct input is used
    }
    
    setEthPrice(newPrice.toString());
    
    // Calculate and update reserve amount
    const reserveEth = 171088;
    const reserveUsd = Math.round(reserveEth * parseFloat(newPrice));
    setReserveAmount(formatNumber(reserveUsd));
  };

  const tabs = [
    { id: 'custom', label: 'Custom Split' },
    { id: 'even', label: 'Even Split' },
    { id: 'hex', label: 'HEX/PLSX' },
    { id: 'hexplsx', label: 'HEX/PLSX/INC' },
  ];

  const getUnselectedTokens = () => {
    const selectedTokens = inputValues.map(input => input.token);
    return tokens.filter(token => !selectedTokens.includes(token));
  };

  return (
    <div className="NewUi">
      <div className="AppHeader">
        <div className="Header">
          <div className="LogoSection">
            <img 
              src="/logo192.png"
              alt="PAMPI Logo" 
              className="PampiLogo"
            />
          </div>
          <div className="Socials">
            <a 
              href="https://x.com/roonskies93" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaXTwitter className="social-icon" />
            </a>
            <a 
              href="https://t.me/pampi_app" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <FaTelegram className="social-icon" />
            </a>
          </div>
        </div>
        <div className="Line">
          <div className="Line1"></div>
        </div>
      </div>

      <div className="calculator">
        <div className="Heading">
          <div className="PriceAppreciationSimulator">
            Price Appreciation Simulator
          </div>
        </div>

        <div className="CalcSection">
          <div className="ChipSection">
            <div className="ChipTabs">
              {tabs.map(tab => (
                <div
                  key={tab.id}
                  className={`${tab.id === selectedTab ? 'CustomTab' : ''}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <div className={tab.id === selectedTab ? 'Selected' : 'Unselected'}>
                    {tab.label}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={`InputSectionFrame ${inputValues.length > 2 ? 'InputSectionFrame-Large' : 'InputSectionFrame-Small'}`}>
            <div className={`CalcCore ${inputValues.length > 2 ? 'CalcCore-Large' : 'CalcCore-Small'}`}>
              <div className="Frame22">
                <div className="Calculator">Calculator</div>
                <button className="Button" onClick={handleAddField}>
                  <div className="IconPlus">
                    <div className="Vector"></div>
                    <div className="Vector"></div>
                  </div>
                </button>
              </div>

              <div className="InputFieldsContainer">
                <div className="InputRow">
                  {inputValues.slice(0, 2).map((field, index) => (
                    <div 
                      key={index}
                      className={`InputField ${inputValues.length === 1 ? 'InputField-FirstRow-Large' : 'InputField-FirstRow-Small'}`}
                    >
                      <div className="amount-input">
                        <span className="dollar-prefix">$</span>
                        <input
                          type="text"
                          value={field.amount}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          placeholder="0"
                        />
                      </div>
                      <div className="token-dropdown">
                        <img 
                          src={tokenData[field.token].image} 
                          alt={field.token} 
                          className="token-icon"
                        />
                        <select
                          value={field.token}
                          onChange={(e) => handleTokenChange(index, e.target.value)}
                        >
                          {getAvailableTokens(index).map(token => (
                            <option key={token} value={token}>
                              {token}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
                
                {inputValues.length > 2 && (
                  <div className="InputRow">
                    {inputValues.slice(2).map((field, index) => (
                      <div 
                        key={index + 2}
                        className={`InputField ${inputValues.length === 3 ? 'InputField-SecondRow-Large' : 'InputField-SecondRow-Small'}`}
                      >
                        <div className="amount-input">
                          <span className="dollar-prefix">$</span>
                          <input
                            type="text"
                            value={field.amount}
                            onChange={(e) => handleInputChange(index + 2, e.target.value)}
                            placeholder="0"
                          />
                        </div>
                        <div className="token-dropdown">
                          <img 
                            src={tokenData[field.token].image} 
                            alt={field.token} 
                            className="token-icon"
                          />
                          <select
                            value={field.token}
                            onChange={(e) => handleTokenChange(index + 2, e.target.value)}
                          >
                            {getAvailableTokens(index + 2).map(token => (
                              <option key={token} value={token}>
                                {token}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <button className="calc-btn" onClick={handleCalculate} disabled={isCalculating}>
                <div className="Continue">
                  {isCalculating ? (
                    <div className="spinner"></div>
                  ) : (
                    'Calculate'
                  )}
                </div>
              </button>
            </div>

            <div className={`InfoLayer ${inputValues.length > 2 ? 'InfoLayer-Large' : 'InfoLayer-Small'}`}>
              <div className="InputGroup">
                <span className="InputLabel">
                  Pulsechain Reserve:
                  <span className="info-icon-wrapper">
                    <FaInfoCircle 
                      className="info-icon"
                      onClick={() => setShowInfoTooltip(!showInfoTooltip)}
                      onMouseEnter={() => setShowInfoTooltip(true)}
                      onMouseLeave={() => setShowInfoTooltip(false)}
                    />
                    {showInfoTooltip && (
                      <>
                        <div className="tooltip-overlay" onClick={() => setShowInfoTooltip(false)}></div>
                        <div className="info-tooltip">
                          Pulsechain Reserve are the supposed sacrifice funds that have been used by OA in the past to buyback HEX, PLS, PLSX and INC. There are currently 171K ETH sitting across sacrifice addresses split by OA into many other addresses. Speculators speculate that some or all of these funds will be used to do many more buybacks.
                        </div>
                      </>
                    )}
                  </span>
                </span>
                <input
                  type="text"
                  value={`$${reserveAmount}`}
                  onChange={(e) => handleReserveChange(e.target.value)}
                  className="InfoInput"
                />
              </div>
              <div className="InputGroup">
                <span className="InputLabel eth-label">ETH:</span>
                <input
                  type="text"
                  value={`$${ethPrice}`}
                  onChange={(e) => handleEthPriceChange(e.target.value)}
                  className="InfoInputETH"
                />
                <input
                  className="ETHSlider"
                  type="range"
                  min="0"
                  max="200"
                  step="50"  // Creates 5 snap points for 1x, 2x, 4x, 8x, 16x
                  value={sliderValue}
                  onChange={(e) => handleEthPriceChange(parseInt(e.target.value), true)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`ResultsSection ${showResults ? 'visible' : ''} ${showVisualizations ? 'visualization-mode' : ''}`}>
          {showResults ? (
            <>
              <div className="ResultsHeading">
                <div className="ResultsHeading-heading">
                  <div className="Results">
                    {!showVisualizations ? 'Results' : 'Purchase Visualization'}
                  </div>
                  <div className="results-buttons">
                    <button 
                      className="how-it-works-btn"
                      onClick={() => setShowHowItWorks(true)}
                    >
                      <div className="Note">Explainer</div>
                    </button>
                    <button 
                      className={`visualization-toggle-btn ${showVisualizations ? 'show-results' : ''}`}
                      onClick={() => {
                        // Toggle visualization state
                        setShowVisualizations(!showVisualizations);
                        
                        // Add a small delay to allow state update to complete
                        setTimeout(() => {
                          // Find the results section
                          const resultsSection = document.querySelector('.ResultsSection');
                          if (resultsSection) {
                            // Scroll to position the results section at the top of the viewport
                            resultsSection.scrollIntoView({ 
                              behavior: 'smooth',
                              block: 'start'
                            });
                          }
                        }, 100);
                      }}
                    >
                      <div className="Note">
                        {showVisualizations ? 'Show Results' : 'Visualiser'}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              
              {showHowItWorks && (
                <div className="how-it-works-modal">
                  <div className="modal-content">
                    <button 
                      className="modal-close-x"
                      onClick={() => setShowHowItWorks(false)}
                    >
                      ×
                    </button>
                    <h2>How pampi.app Simulates Token Purchases</h2>
                    
                    <p className="main-description">
                      When you enter an amount for a specific token, pampi.app doesn't just simulate a single large purchase—it breaks it down into smaller chunks to keep slippage below 2.5%.
                    </p>
                    <p className="main-description">
                      It then follows the exact trading logic of a Decentralized Exchange (DEX), ensuring a realistic simulation of how your purchase would affect the market price.
                    </p>

                    <div className="section">
                      <h3>Step-by-Step Breakdown</h3>
                      <ol>
                        <li>
                          <strong>Chunking the Purchase:</strong>
                          <p>Instead of buying the entire amount in one go (which would cause high slippage), pampi splits it into smaller transactions.</p>
                        </li>
                        <li>
                          <strong>Following the Trade Route:</strong>
                          <ul>
                            <li>Each token has a specific trading route based on liquidity pools.</li>
                            <li>The app determines the correct route to use for the swap.</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Simulating Trades and Updating Prices:</strong>
                          <ul>
                            <li>The first chunk of your purchase is executed, just like a real DEX trade.</li>
                            <li>The liquidity pool amounts are updated accordingly, which affects the token price.</li>
                            <li>The app keeps track of the new price and proceeds to the next chunk.</li>
                          </ul>
                        </li>
                      </ol>
                    </div>

                    <div className="section">
                      <h3>Example: Buying $60M in HEX</h3>
                      <ol className="steps">
                        <li>The simulator starts by purchasing $200K worth of PLS using the PLS/DAI liquidity pool.</li>
                        <li>After the trade, the PLS price changes due to the updated liquidity pool.</li>
                        <li>The simulator then uses the newly bought PLS to purchase HEX from the HEX/WPLS pool.</li>
                        <li>This swap also affects HEX's price because of the change in liquidity.</li>
                        <li>The process repeats until the full $60M is spent, with each step adjusting liquidity and recalculating prices in real-time.</li>
                      </ol>
                    </div>

                    <div className="section">
                      <h3>Why This Matters</h3>
                      <p className="main-description">
                        This method closely mirrors real-world trading behavior on automated market makers (AMMs) like Uniswap. It ensures that users get a realistic view of how large purchases impact the market, rather than assuming a static price.
                      </p>
                    </div>

                    <button 
                      className="close-modal-btn"
                      onClick={() => setShowHowItWorks(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}

              {!showVisualizations ? (
                <div className="Table">
                  <div className="ResultsHeadings">
                    <div className="Ticker">
                      <div className="TickerText">Ticker</div>
                    </div>
                    <div className="InitialPrice">
                      <div className="InitialPriceText">Initial Price</div>
                    </div>
                    <div className="NewPrice" style={{ minWidth: '173px' }}>
                      <div className="NewPriceText">New Price</div>
                    </div>
                  </div>
                  {results.filter(result => inputValues.some(input => input.token === result.ticker)).map((result, index) => (
                    <div key={index} className="TableRow">
                      <div className="TableItem">
                        <img className="TickerIcon" src={tokenData[result.ticker].image} alt={result.ticker} />
                        <span className="TickerLabel">{result.ticker}</span>
                      </div>
                      <div className="TableItem">
                        <span className="PriceLabel">${result.initialPrice}</span>
                      </div>
                      <div className="TableItem" style={{ minWidth: '173px' }}>
                        <span className="PriceLabel">${result.newPrice}</span>
                        <div className="IncreaseIndicator">
                          <div className="ArrowForward">
                            <i className="fas fa-arrow-up Vector"></i>
                          </div>
                          <span className="IncreaseText">{result.increase}</span>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="ReflexivityHeader">
                    <div className="TableItem">
                      <span className="ReflexivityText">Reflexivity</span>
                    </div>
                  </div>

                  {results.filter(result => !inputValues.some(input => input.token === result.ticker)).map((result, index) => (
                    <div key={`unselected-${index}`} className="TableRow">
                      <div className="TableItem">
                        <img className="TickerIcon" src={tokenData[result.ticker].image} alt={result.ticker} />
                        <span className="TickerLabel">{result.ticker}</span>
                      </div>
                      <div className="TableItem">
                        <span className="PriceLabel">${result.initialPrice}</span>
                      </div>
                      <div className="TableItem" style={{ minWidth: '173px' }}>
                        <span className="PriceLabel">${result.newPrice}</span>
                        <div className="IncreaseIndicator">
                          <div className="ArrowForward">
                            <i className="fas fa-arrow-up Vector"></i>
                          </div>
                          <span className="IncreaseText">{result.increase}</span>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="PampiBrandingHeader">
                    <div className="TableItem">
                      <div className="PampiBrandingContent">
                        <img src={pampiLogo} alt="PAMPI" className="PampiBrandingLogo" />
                        <span className="PampiBrandingText">pampi.app</span>
                      </div>
                      <div className="donate-container">
                        <button 
                          className="donate-button"
                          onClick={() => {
                            navigator.clipboard.writeText("0xD2B2D23D8ed2D3B4C8ddf3d8C4Eb855F0B9d672f")
                              .then(() => {
                                // Create a temporary state variable for this component
                                const tooltip = document.createElement("div");
                                tooltip.className = "eth-address-tooltip";
                                tooltip.textContent = "Copied: 0xD2B2...672f";
                                
                                // Find the donate container and append the tooltip
                                const container = document.querySelector(".PampiBrandingHeader .donate-container");
                                if (container) {
                                  container.appendChild(tooltip);
                                  
                                  // Remove the tooltip after 2 seconds
                                  setTimeout(() => {
                                    if (tooltip.parentNode === container) {
                                      container.removeChild(tooltip);
                                    }
                                  }, 2000);
                                }
                              })
                              .catch(err => {
                                console.error('Failed to copy: ', err);
                              });
                          }}
                        >
                          Tip us
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                showVisualizations && simulationData && (
                  <div className="visualization-section">
                    {/* Temporarily disabled TransactionFlowVisual
                    <TransactionFlowVisual 
                      selectedTokens={inputValues.map(input => input.token)} 
                      data={simulationData}
                    />
                    */}
                    
                    <PurchaseProgressVisual 
                      simulationData={simulationData}
                      inputValues={inputValues}
                    />

                  </div>
                )
              )}
            </>
          ) : null}
        </div>

        {/* <PriceTracker tokenData={tokenData} /> */}
      </div>
      <Analytics />
    </div>
  );
}

export default App;
