import React, { useState, useEffect } from 'react';
import './PurchaseProgressVisual.css';
import pampiLogo from '../assets/pampi-branding.png';

const PurchaseProgressVisual = ({ simulationData, inputValues }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showEthAddress, setShowEthAddress] = useState(false);
  const maxSteps = simulationData?.results?.length || 0;
  const ethAddress = "0xD2B2D23D8ed2D3B4C8ddf3d8C4Eb855F0B9d672f";
  
  // Add token data for images (matching App.js)
  const tokenData = {
    PLS: {
      symbol: 'PLS',
      image: '/images/pls.png'
    },
    HEX: {
      symbol: 'HEX',
      image: '/images/hex.png'
    },
    PLSX: {
      symbol: 'PLSX',
      image: '/images/plsx.png'
    },
    INC: {
      symbol: 'INC',
      image: '/images/inc.png'
    }
  };
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(ethAddress)
      .then(() => {
        setShowEthAddress(true);
        setTimeout(() => setShowEthAddress(false), 2000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };
  
  // Extract price progression from simulation data
  const getPriceProgression = () => {
    if (!simulationData?.results) return [];
    
    let cumulativeAmount = 0;
    return simulationData.results.map((result, index) => {
      const purchaseAmount = parseFloat(result.sacMoney);
      cumulativeAmount += purchaseAmount;
      return {
        step: index + 1,
        prices: {
          PLS: parseFloat(result.newPricesAndLPValues.pricePLS),
          PLSX: parseFloat(result.newPricesAndLPValues.pricePLSX),
          INC: parseFloat(result.newPricesAndLPValues.priceINC),
          HEX: parseFloat(result.newPricesAndLPValues.priceHEX)
        },
        purchaseAmount: purchaseAmount,
        cumulativeAmount: cumulativeAmount,
        ticker: result.ticker
      };
    });
  };
  
  const progression = getPriceProgression();
  
  useEffect(() => {
    let interval = null;
    if (isPlaying && currentStep < maxSteps - 1) {
      interval = setInterval(() => {
        setCurrentStep(step => step + 1);
      }, 200);
    } else if (currentStep >= maxSteps - 1) {
      setIsPlaying(false);
    }
    
    return () => clearInterval(interval);
  }, [isPlaying, currentStep, maxSteps]);
  
  const handlePlayToggle = () => {
    if (currentStep >= maxSteps - 1) {
      setCurrentStep(0);
    }
    setIsPlaying(!isPlaying);
  };
  
  const formatPrice = (price) => {
    if (!price) return '0';
    
    if (price < 0.0001) {
      return price.toFixed(6);
    } else if (price < 0.01) {
      return price.toFixed(4);
    } else if (price < 0.1) {
      return price.toFixed(3);
    } else {
      return price.toFixed(2);
    }
  };
  
  if (!progression.length) return null;
  
  const currentProgress = progression[currentStep] || progression[0];
  const initialPrices = {
    PLS: parseFloat(simulationData.originalPricesAndLPValues.pricePLS),
    PLSX: parseFloat(simulationData.originalPricesAndLPValues.pricePLSX),
    INC: parseFloat(simulationData.originalPricesAndLPValues.priceINC),
    HEX: parseFloat(simulationData.originalPricesAndLPValues.priceHEX)
  };
  
  return (
    <div className="purchase-progress-container">
      
      <div className="controls-purchase-container">
        <div className="progress-controls">
          <button 
            className={`play-button ${isPlaying ? 'playing' : ''}`}
            onClick={handlePlayToggle}
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
          
          <div className="progress-slider">
            <input 
              type="range" 
              min="0" 
              max={maxSteps - 1} 
              value={currentStep} 
              onChange={(e) => setCurrentStep(parseInt(e.target.value))}
            />
            <div className="step-label">
              Step {currentStep + 1} of {maxSteps}
            </div>
          </div>
        </div>
        
        <div className="current-purchase">
          <div className="purchase-details">
            <div className="purchase-info">
              <span>Purchasing:</span> 
              <strong>${Math.round(currentProgress.purchaseAmount).toLocaleString()}</strong> of <strong>{currentProgress.ticker}</strong>
            </div>
            <div className="cumulative-info">
              <span>Purchased:</span> <strong>${Math.round(currentProgress.cumulativeAmount).toLocaleString()}</strong>
            </div>
          </div>
        </div>
      </div>
      
      <div className="price-progress-table">
        <div className="price-progress-header">
          <div className="token-cell">Token</div>
          <div className="price-cell">Initial Price</div>
          <div className="price-cell">Current Price</div>
          <div className="change-cell">Change</div>
        </div>
        
        {Object.keys(currentProgress.prices).map(token => {
          const initialPrice = initialPrices[token];
          const currentPrice = currentProgress.prices[token];
          const multiplier = (currentPrice / initialPrice).toFixed(2);
          
          return (
            <div key={token} className="price-progress-row">
              <div className="token-cell">
                <img className="TickerIcon" src={tokenData[token].image} alt={token} />
                <span className="TickerLabel">{token}</span>
              </div>
              <div className="price-cell">${formatPrice(initialPrice)}</div>
              <div className="price-cell">${formatPrice(currentPrice)}</div>
              <div className="change-cell">
                <span className={parseFloat(multiplier) > 1 ? 'positive-change' : 'negative-change'}>
                  {multiplier}x
                </span>
              </div>
            </div>
          );
        })}
        <div className="PampiBrandingHeader">
            <div className="TableItem">
              <div className="PampiBrandingContent">
                <img src={pampiLogo} alt="PAMPI" className="PampiBrandingLogo" />
                <span className="PampiBrandingText">pampi.app</span>
              </div>
              <div className="donate-container">
                <button 
                  className="donate-button"
                  onClick={copyToClipboard}
                >
                  Tip us
                </button>
                {showEthAddress && (
                  <div className="eth-address-tooltip">
                    Copied: {ethAddress.substring(0, 6)}...{ethAddress.substring(ethAddress.length - 4)}
                  </div>
                )}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseProgressVisual;
